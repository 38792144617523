import { useEffect, useState } from 'react';
import { ContactUs } from '../contact_us/contact_us';

export const ContactUsWrapper = () => {
  const [show, setShow] = useState(false);

  const setContactFormVisible = () => {
    if (global.scrollY > 400) {
      if (!show) {
        setShow(true);
      }
    }
  };

  useEffect(() => {
    global.window.addEventListener('scroll', setContactFormVisible);
    return () => {
      global.window.removeEventListener('scroll', setContactFormVisible);
    };
  }, []);
  //Style is added to fix the case when page renders for the first time and the anchor tag doesn't reaches "contact us" as it is not present in the dom at that moment
  return (
    <div id="contact-us" style={{ minHeight: '500px' }}>
      {show && <ContactUs />}
    </div>
  );
};
