/* eslint-disable no-return-assign */
import React, { useRef } from 'react';
import Slider from 'react-slick';
import Image from 'next/legacy/image';

import ArrowNextSVG from 'src/common/icons/arrow-next.svg';
import ArrowPrevSVG from 'src/common/icons/arrow-prev.svg';
import Story from './Story/Story';
import s from './Stories.module.scss';
import { StrapiImageTypes } from '../../../../utils/types';

type StoryList = {
  id: number;
  title: string;
  subtitle: string;
  author: string;
  published_date: string;
  brand_logo: StrapiImageTypes;
  url: string;
};

type Data = {
  heading: string;
  sub_heading: string;
  stories_list: StoryList[];
  quotes_img: StrapiImageTypes;
};

type StoriesProps = {
  data: Data;
};

export const Stories = ({ data }: StoriesProps) => {
  const sliderRef = useRef();
  const next = () => {
    //   @ts-ignore
    sliderRef.current.slickNext();
  };

  const previous = () => {
    //   @ts-ignore
    sliderRef.current.slickPrev();
  };
  return (
    <div className={s.container}>
      <div className={s.top}>
        <div className={s.quotes_img}>
          <Image
            src={data?.quotes_img?.data?.attributes?.url}
            layout="fill"
            alt={data?.quotes_img?.data?.attributes?.alternativeText}
          />
        </div>
        <h3 className={s.heading}>{data?.heading}</h3>
        <p className={s.sub_heading}>{data?.sub_heading}</p>
      </div>

      <Slider ref={(slider) => (sliderRef.current = slider)}>
        {data?.stories_list.map((story) => (
          <div className={s.story_container} key={story.id}>
            <Story story={story} />
          </div>
        ))}
      </Slider>
      <div style={{ textAlign: 'center' }} className={s.action}>
        <button className={s.btn} type="button" onClick={() => previous()}>
          <ArrowPrevSVG />
        </button>
        <button className={s.btn} type="button" onClick={() => next()}>
          <ArrowNextSVG />
        </button>
      </div>
    </div>
  );
};
