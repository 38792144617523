/* eslint-disable no-return-assign */
/* eslint-disable react/require-default-props */
import React, { useRef, useEffect, useState } from 'react';
import Image from 'next/legacy/image';
import classnames from 'classnames';
import Slider from 'react-slick';
import s from './OurPartners.module.scss';
import { StrapiImageTypes } from '../../../../utils/types';

type Clients = {
  id: number;
  logo: StrapiImageTypes;
};

type Data = {
  title: string;
  subtitle: string;
  clients: Clients[];
};

type OurPartnersProps = {
  data: Data;
  customStyle?: any;
  className?: string;
};

export const OurPartners = ({
  data,
  customStyle,
  className,
}: OurPartnersProps) => {
  const sliderRef = useRef();
  const [width, setWidth] = useState<number>();
  const [sliderSetting, setSliderSetting] = useState({
    dots: false,
    infinite: true,
    speed: 2000,
    slidesToShow: 2,
    slidesToScroll: 1,
    arrows: false,
    autoplay: true,
    autoplaySpeed: 300,
    rows: 3,
  });

  useEffect(() => {
    setWidth(global.innerWidth);
    const handleWindowResize = () => {
      setWidth(global.innerWidth);
    };
    global.addEventListener('resize', handleWindowResize);
    return () => global.removeEventListener('resize', handleWindowResize);
  }, []);

  useEffect(() => {
    if (width < 450) {
      setSliderSetting({
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 2,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 300,
        rows: 3,
      });
    } else {
      setSliderSetting({
        dots: false,
        infinite: true,
        speed: 2000,
        slidesToShow: 4,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        autoplaySpeed: 300,
        rows: 2,
      });
    }
  }, [width]);

  useEffect(() => {
    // @ts-ignore
    if (sliderRef.current.slickPlay) {
      // @ts-ignore
      sliderRef.current.slickPlay();
    }
  }, []);

  return (
    <div className={classnames(s.root, className)} style={customStyle}>
      <div className={classnames(s.container, 'cmn_container_no_padding')}>
        <div className={s.content}>
          <h3 className={s.title}>{data?.title}</h3>
          <p className={s.subtitle}>{data?.subtitle}</p>
        </div>
      </div>

      <div
        className={s.slider}
        ref={(slider: any) => (sliderRef.current = slider)}
      >
        <Slider {...sliderSetting}>
          {data?.clients.map((client) => (
            <div className={s.item} key={client.id}>
              <div className={s.img}>
                <Image
                  src={client?.logo?.data?.attributes?.url}
                  layout="fill"
                  objectFit="contain"
                  priority
                  alt={client?.logo?.data?.attributes?.alternativeText}
                />
              </div>
            </div>
          ))}
        </Slider>
      </div>
      <div className={s.overlay} />
      {/* <div className={s.overlay2} /> */}
    </div>
  );
};
