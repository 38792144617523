/* eslint-disable arrow-body-style */
import React from 'react';
import Image from 'next/legacy/image';
import s from './Story.module.scss';

type StoryList = {
  id: number;
  title: string;
  subtitle: string;
  author: string;
  published_date: string;
  brand_logo: any;
  url: string;
};

type StoryProps = {
  story: StoryList;
};

const Story = ({ story }: StoryProps) => {
  return (
    <div
      className={s.container}
      onClick={() => global.open(story.url, '_blank')}
    >
      <div className={s.top}>
        <div className={s.img}>
          <Image
            src={story?.brand_logo?.data?.attributes?.url}
            layout="fill"
            objectFit="contain"
            objectPosition="left"
            alt={story?.brand_logo?.data?.attributes?.alternativeText}
          />
        </div>
      </div>

      <div className={s.content}>
        <h3 className={s.title}>
          <div className={s.quotes}>&#x201C;</div>
          {story?.title}
        </h3>
        <p className={s.subtitle}>{story?.subtitle}</p>
        <a
          href={story?.url}
          className={s.read}
          target="_blank"
          rel="noreferrer"
        >
          Read more...
        </a>
        <p className={s.author}>by {story?.author}</p>
        <p className={s.date}>{story?.published_date}</p>
      </div>
    </div>
  );
};

export default Story;
