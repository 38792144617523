import React, { useRef } from 'react';
import cn from 'classnames';
import Image from 'next/legacy/image';
import ArrowNextSVG from 'src/common/icons/arrow-next.svg';
import ArrowPrevSVG from 'src/common/icons/arrow-prev.svg';
import Story from '../Stories/Story/Story';
import s from './StoriesV2.module.scss';
import { StrapiImageTypes } from '../../../../utils/types';

type StoryList = {
  id: number;
  title: string;
  subtitle: string;
  author: string;
  published_date: string;
  brand_logo: any;
  url: string;
};

type Data = {
  heading: string;
  sub_heading: string;
  stories_list: StoryList[];
  quotes_img: StrapiImageTypes;
};

type StoriesV2Props = {
  data: Data;
};

export const StoriesV2 = ({ data }: StoriesV2Props) => {
  const containerRef = useRef();

  const handleNextClick = () => {
    //   @ts-ignore
    containerRef.current.scrollLeft += 400;
  };
  const handlePrevClick = () => {
    //   @ts-ignore
    containerRef.current.scrollLeft -= 400;
  };

  return (
    <div className={s.container}>
      <div className={s.top}>
        <div className={s.quotes_img}>
          <Image
            src={data?.quotes_img?.data?.attributes?.url}
            layout="fill"
            alt={data?.quotes_img?.data?.attributes?.alternativeText}
          />
        </div>
        <h3 className={s.heading}>{data?.heading}</h3>
        <p className={s.sub_heading}>{data?.sub_heading}</p>
      </div>
      <button onClick={handlePrevClick} type="button" className={s.btn}>
        <ArrowPrevSVG />
      </button>
      <div className={s.grid} ref={containerRef}>
        <div className={cn(s.grid_item, s.a)}>
          <Story story={data?.stories_list[0]} />
        </div>
        <div className={cn(s.grid_item, s.b)}>
          <Story story={data?.stories_list[1]} />
        </div>
        <div className={cn(s.grid_item, s.c)}>
          <Story story={data?.stories_list[2]} />
        </div>
        <div className={cn(s.grid_item, s.d)}>
          <Story story={data?.stories_list[3]} />
        </div>

        <div className={cn(s.grid_item, s.e)}>
          <Story story={data?.stories_list[4]} />
        </div>
        <div className={cn(s.grid_item, s.f)}>
          <Story story={data?.stories_list[5]} />
        </div>
        <div className={cn(s.grid_item, s.g)}>
          <Story story={data?.stories_list[6]} />
        </div>
        <div className={cn(s.grid_item, s.h)}>
          <Story story={data?.stories_list[7]} />
        </div>
        <div className={cn(s.grid_item, s.i)}>
          <Story story={data?.stories_list[8]} />
        </div>
        <div className={cn(s.grid_item, s.j)}>
          <Story story={data?.stories_list[9]} />
        </div>
        <div className={cn(s.grid_item, s.k)}>
          <Story story={data?.stories_list[10]} />
        </div>
        <div className={cn(s.grid_item, s.l)}>
          <Story story={data?.stories_list[11]} />
        </div>

        <div className={s.left_blur} />
      </div>
      <button onClick={handleNextClick} type="button" className={s.btn2}>
        <ArrowNextSVG />
      </button>
    </div>
  );
};
