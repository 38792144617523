/* eslint-disable react/require-default-props */
import React from 'react';

type MetaIndexProps = {
  index?: boolean | null;
};

export function MetaIndex({ index = null }: MetaIndexProps) {
  if (index === false)
    return <meta name="robots" content="noindex, nofollow" />;
  if (index === true) return <meta name="robots" content="index, follow" />;
  return null;
}

export default MetaIndex;
